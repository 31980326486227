import React, {useEffect, useState} from "react";
import Layout from "../components/Layout";
import { Helmet } from 'react-helmet'
import { Link } from "exo";

const Accepted = () => {
  const [jqueryReady, setJqueryReady] = useState(false);
  const [inviteRobotReady, setInviteRobotReady] = useState(false);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      loadJQuery(() => {
        setJqueryReady(true);
      });  
    
      loadInviteRobot(() => {
        setInviteRobotReady(true);
      }); 
    }
  }, []);

  const loadInviteRobot = (callback) => {
  
    const existingScript = document.getElementById('inviterobot');
  
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://app.inviterobot.com/widget/1.4.0/fcd66f9168fb8d124fd42fdf216ea7c9d030817c.js'; // URL for the third-party library being loaded.
      script.id = 'inviterobot'; 
      document.body.appendChild(script);
  
      script.onload = () => {
        if (callback) callback();
      };
    }
  
    if (existingScript && callback) callback();
  };

  const loadJQuery = (callback) => {
    
    const existingScript = document.getElementById('jquery');
  
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://code.jquery.com/jquery-2.1.1.min.js'; // URL for the third-party library being loaded.
      script.id = 'jquery'; 
      document.body.appendChild(script);
  
      script.onload = () => {
        if (callback) callback();
      };
    }
  
    if (existingScript && callback) callback();
  };
  

  return (
    <>
    <Helmet>
            <meta charSet="utf-8" />
            <title>Auto Genius - You've Been Accepted!</title>
            
      </Helmet>
      <Layout>
    
      <div className="max-w-5xl pt-36 md:pt-48 px-10 md:px-2 mx-auto">
        <h1 className="billrightTitle text-center text-3xl md:text-5xl font-bold mb-20">
          You've Been Accepted!
        </h1>
        <div className="text-black text-center text-md b-10 max-w-4xl mx-auto terms">
        <p><strong>Join the top 10% of automotive marketing professionals who've chosen to innovate faster, together, inside Auto Genius.</strong><br />Join today, by pressing the button below.</p>

        {jqueryReady && (
          <button className="text-center rounded-md py-4 bg-black text-white font-bold cursor-pointer my-10 mb-20 px-10 transition-all hover:bg-yellow hover:border-2 hover:text-black" data-inviterobot-trigger="data-inviterobot-trigger" data-plan-id="922">Pay 50.00 USD every month {inviteRobotReady}</button>
        )}
        </div>
      </div>
      <hr size="1" className="mt-5 mb-5"/>
    </Layout></>
  );
};

export default Accepted;
